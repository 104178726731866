const secrets = {
    bingKey: 'AkGvL26EZY8basqADV1APUoHM-1jBaP4XJdZw9xLF5Yben1IZ88e34rUAs3Ovc9B',
    thunderforestKey: '00000000000000000000000000000000',
    sentryDSN: 'https://00000000000000000000000000000000@sentry.io/111111',
    mapillary: '0000000000000000000000000000000000000000000000000000',
    flickr: '00000000000000000000000000000000',
    google: 'AIzaSyDeLYThsNCQhdWpiTg2xMSGOOu162s5KqA'
};

export default secrets;
