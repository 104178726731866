import secrets from './secrets';

const config = {
    caption: `
        <a href="https://vesture.dodies.lv/help.php">Par lapu</a> |
        <a href="mailto:dodies@dodies.lv" target="_self">dodies@dodies.lv</a> `,
    defaultLocation: [56.91950, 24.16786],
    defaultZoom: 10,
    maxZoom: 24,
    useSentry: false,
    googleApiUrl: `https://maps.googleapis.com/maps/api/js?v=3&key=${secrets.google}`,
    westraDataBaseUrl: 'https://nakarte.me/westraPasses/',
    CORSProxyUrl: 'https://proxy.nakarte.me/',
    elevationsServer: [
        {
            name: 'elevation.nakarte.me',
            url: 'https://elevation.nakarte.me/'
        },
        {
            name: 'Reljefs DRM',
            url: 'https://elevate.anrijs.lv/'
        },
        {
            name: 'Virsma DVM',
            url: 'https://elevate2.anrijs.lv/'
        },
    ],
    wikimediaCommonsCoverageUrl: 'https://tiles.nakarte.me/wikimedia_commons_images/{z}/{x}/{y}',
    geocachingSuUrl: 'https://nakarte.me/geocachingSu/geocaching_su2.json',
    tracksStorageServer: 'https://tracks.nakarte.me',
    ...secrets
};

export default config;
